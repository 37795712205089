<!--
 * @Author: 余生 906524998@qq.com
 * @Date: 2024-01-18 18:16:19
 * @LastEditors: 余生 906524998@qq.com
 * @LastEditTime: 2024-01-19 15:56:50
 * @FilePath: \cooask_frontend\src\views\sampleReport\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="admin-wrapper">
    <div class="news">
 
      <div class="sampleReport-box">
          <div class="basic-btn color-btn" @click="handleReportGereate">
              生成简报
            </div>
        </div>
     
        <el-table
      :data="newsList"
      style="width: 100%"
      row-key="id"
      @selection-change="handleSelectTableList"
      >
      <el-table-column
      type="selection"
      reserve-selection
      width="55"
      align="center"
      >
    </el-table-column>
      <el-table-column
      label="序号"
      type="index"
      width="65">
    </el-table-column>
      <el-table-column
        prop="date"
        label="标签"
        width="120">
        <template slot-scope="{row}">
            <div class="catogory">
                【{{
              row.category == 0
                ? "未知"
                : row.category == 12
                ? "决策参考"
                : row.category == 13
                ? "行业动态"
                : ""
            }}】
                </div>
      </template>
      </el-table-column>
      <el-table-column
      class="title"
      prop="title"
      label="标题"
      show-overflow-tooltip>
      <template slot-scope="{row}">
     <span class="table-title" v-html="row.title"  @click="handleViewDetail(row)" style="cursor:pointer"></span>
    </template>
    </el-table-column >
   
   
    <el-table-column
      prop="source"
      label="来源"
      width="120" show-overflow-tooltip>
    </el-table-column>
    <el-table-column
      prop="send_time"
      label="采集时间"
      width="180">
    </el-table-column>
    <el-table-column
    prop="operator.nickname"
      label="操作人"
      width="100"
      >
    </el-table-column>
       </el-table>
       <div :class="['more', { loading: loading }]" v-loading="loading">
      <span v-show="scrollCount > 2 && more" @click="handleMore">加载更多</span>
    </div>
    </div>

  </div>
</template>

<script>
export default {
    name: 'sampleReport',

    data() {
    return {
        more: true,
        scrollCount: 0,
        page: 1,
        page_count:0,
        loading: false,
        seleTableList:[],
        newsList:[],
      categoryList:[{label:12,value:'决策参考'},{label:13,value:'行业动态'}]
    };
  },
    computed:{
        token() {
      return this.$store.state.token;
    },
    userInfo() {
      return this.$store.state.userInfo;
    }, 
    },
    activated() {
    console.log('activied');
    this.loading = false;
    this.more = true;
    this.scrollCount = 0;
    this.handleSearch();
    window.addEventListener("scroll", this.handleScroll);
  },
  deactivated() {
    window.removeEventListener("scroll", this.handleScroll);
  },

    methods: {
        async getNews() {
      // start_time  !== 'undefined' 刷新
      // start_time  === 'undefined' 更多
      let res = await this.api.postFormAPISM(
        {
          page:this.page,
          limit: 20,
        },
        "/admins/getbriefingpoollist"
      );
      console.log("getNews",res);
      this.page_count = res.data.data.pagination.page_count;
      return res.data.data.briefing_pool_info_list
      // this.start_time = this.newsList[this.newsList.length - 1].send_time;
    },
        handleLogin() {
      if (!this.token) {
        this.$message({
          type: "warning",
          message: "请先登录",
        });
        return this.handleOpenLogin();
      }
    },
    handleOpenLogin() {
      this.$login.show();
    },
        handleReportGereate() {
      if (!this.token) {
        this.$message({
          type: "warning",
          message: "请先登录",
        });
        return this.handleOpenLogin();
      }
      if (!this.seleTableList.length) {
        return this.$message({
          type: "warning",
          message: "至少选择一篇文章",
        });
      }
     
    let id_list =  this.seleTableList.map(item=>item.id);
   window.open("http://cooask.com/api/admins/generatebriefing?id_list=" +JSON.stringify(id_list)
     );
    },
        handleViewDetail({ id }) {
      let text = this.$router.resolve({
        path: "/newsDetail",
        query: {
          id,
          article_type:2,
        },
      });
      console.log("text", text.href);
      // 打开一个新的页面
      window.open(text.href, "_blank");
    },
    handleScroll() {
      if (!this.newsList.length || this.loading ||  this.page>=this.page_count ) return;

      // 距顶部
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      // 可视区高度
      var clientHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      // 滚动条总高度
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      let targetHeight = scrollHeight - 257 - 140;
      // console.log(scrollTop, clientHeight, scrollHeight, targetHeight,  targetHeight - scrollTop - clientHeight);
      if (targetHeight - scrollTop - clientHeight < 10) {
        this.loading = true;
        // return
        setTimeout(() => {
          this.handleMore("scroll");
        }, 500);
      }
    },
    handleSelectTableList(val)
    {
      this.seleTableList = val;
    },
    async  handleSearch() {
      console.log("start");
      this.newsList = await this.getNews();
 
    },
    async handleMore(text) {

      this.page++;
      let data = await this.getNews();
      this.newsList = [...this.newsList, ...data];
      console.log('page',this.page);
  
      this.more = data.length === this.limit;
          if (text) {
            this.loading = false;
            this.scrollCount++;
            console.log('this.scrollCount',this.scrollCount);
          }
    },
    },
};
</script>

<style lang="less" scoped>
.admin-wrapper {
  width: 1200px;
  overflow: hidden;
  padding: 37px 0 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  & /deep/ .table-title{
      &:hover{
        color: #058e44;
      }
  }
  & > .news {
    & /deep/ .search-wrapper{
      display: flex;
        justify-content: center;
        margin-bottom: 20px;
      &>.el-input{ 
            width:500px;
            &>.el-input__inner{
                border-radius: 30px;
                &:focus,
                &:hover{
                    // border-color: transparent;
                    // outline: unset;
                }
                &::placeholder{
                    color: rgb(117, 117, 117);
                }
            }
            & i{
                color: #4471bc;
                font-size: 14px;
                cursor: pointer;
            }
        }
    }
  &>.news-header{
    display: flex ;
      align-items: center;
      font-size: 14px;
    //   justify-content: flex-end;
      margin-bottom: 20px;
    & /deep/ .select-tab
    {
      flex:1;
      & > .el-radio-group {
                  & > .el-radio {
                    line-height: 3;
                    &.is-checked {
                      & .el-radio__label {
                        background: #058e44;
                        color: #fff;
                        border: unset;
                      }
                    }
                    & .el-radio__input {
                      display: none;
                    }
                    & .el-radio__label {
                      padding: 5px 10px;
                      font-size: 14px;
                      color: #333;
                      border: 1px solid #D1D1D1;
                    }
                  }
          }
    }
    &>.time-wrapper{
     margin-left: 20px;
    }
  }
    &>.sampleReport-box{
      display: flex;
      justify-content: flex-end;
      margin-bottom: 20px;
    }
    & .el-table
    {
        & .catogory{
            color: #058e44;

        }
        & .news-btn {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        font-size: 14px; 
      .btn_hover {
    margin: 0 10px 0 10px ;
    cursor: pointer;
    &:first-of-type{
        margin-left: 0;
    }
      
    &.type--text {
        color: #409EFF;
    }

    &:hover {
        color: #02b980;
    }

    &.not-allowed,
    &[disabled]{
        color: gray !important;
        cursor: not-allowed !important;
    }

}
    }
    }
   }
  
  & > .more {
    padding: 10px 0 0;

    &.loading {
      padding: 35px 0 0;
    }

    & > span {
      display: block;
      padding: 2px 0;
      cursor: pointer;
      width: 296px;
      margin: 0 auto;
      text-align: center;
      font-family: "Arial Normal", "Arial", sans-serif;
      font-weight: 400;
      font-style: normal;
      // font-size: 13px;
      // color: #15a3f0;
      font-size: 14px;
      color: #058e44;
      background-color: rgb(230, 241, 251);
    }

    & .el-loading-spinner {
      margin: 0;
      top: 10px;

      & .circular {
        height: 26px;
        width: 26px;
      }
    }
  }
}
</style>